import { render, staticRenderFns } from "./score1.vue?vue&type=template&id=1fa1c449&scoped=true"
import script from "./score1.vue?vue&type=script&lang=js"
export * from "./score1.vue?vue&type=script&lang=js"
import style0 from "./score1.vue?vue&type=style&index=0&id=1fa1c449&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fa1c449",
  null
  
)

export default component.exports