<!--
 * @Author: huangbinbin
 * @Date: 2024-07-04 16:44:49
 * @LastEditors: huangbinbin
 * @LastEditTime: 2024-08-16 10:57:13
-->
<template>
  <div v-if="info" class="margin">
    <img class="qq" src="@/assets/qq.png" />
     <div class="part1">第一季总决赛 | 葡轩红酒赞助</div> 
    <div class="part2">
      <!-- <div class="p4">{{ info.playAEndSetScore }}</div> -->
      <div class="p1" ref="myDiv1" style="opacity: 0;position: fixed;left: -1000px;top:0px;color: red;">{{
        info.playAName }}</div>
      <div class="p1">
        <div :style="{ 'width': nameLength + 'px' }">{{ info.playAName }}</div>
        <!-- <div><img src="@/assets/left.png" /></div> -->

      </div>
      <div class="p2" v-for="(item, index) in info.playAEndSetInfo" :key="index">{{ item.set_score }}</div>
      <div class="p2" v-if="info.playANowSetInfo">{{ info.playANowSetInfo.set_score }}</div>
      <div class="p3" v-if="info.playANowSetInfo">{{ info.playANowSetInfo.set_now_score }}</div>
      <!-- <div class="p5">AD</div> -->
    </div>
    <div class="part3">
      <!-- <div class="p4">{{ info.playBEndSetScore }}</div> -->
      <div class="p1" ref="myDiv2" style="opacity: 0;position: fixed;left: 0px;top:0px">{{ info.playBName }}</div>
      <div class="p1">
        <div :style="{ 'width': nameLength + 'px' }">{{ info.playBName }}</div>
        <!-- <div><img src="@/assets/qiu.png" /></div> -->

      </div>

      <div class="p2" v-for="(item, index) in info.playBEndSetInfo" :key="index">{{ item.set_score }}</div>
      <div class="p2" v-if="info.playBNowSetInfo">{{ info.playBNowSetInfo.set_score }}</div>
      <div class="p3" v-if="info.playBNowSetInfo">{{ info.playBNowSetInfo.set_now_score }}</div>
    </div>
    <div v-if="info.downTitle" class="part4">{{ info.downTitle }}</div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: '',
  data() {
    return {
      info: '',
      nameLength: 0,
      match_id: ''
    }
  },
  async mounted() {
    let that = this
    console.log(that.$route)
    that.match_id = that.$route.query.match_id
    that.action()
    setInterval(() => {
     that.action()
    }, 1000)

  },
  methods: {
    action() {
      let that = this
      axios({
        url: '/dev/getMatchScoringDetail',
        method: 'post',
        data: {
          match_id: that.match_id
        }
      }).then((res) => {

        if (res.data.state == 'ok') {
          that.info = res.data.data
          that.$nextTick(function () {
            let a = that.$refs.myDiv1.offsetWidth + 100;
            let b = that.$refs.myDiv2.offsetWidth + 100;
            that.nameLength = a >= b ? a : b
          })

          // this.info.playAName='21312312123'
          // this.info.playBName='2'

        }
      }).catch((res) => { })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.margin {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  width: fit-content;
  height: fit-content;
}
.margin .qq{
  width: 310px;
  position: absolute;
  left:-208px;
  top: -94px;
  z-index: -1;
}

.part4 {
  /* width: 259px; */
  padding-left: 10px;
  padding-right: 10px;
  height: 84px;
  background-image: url("@/assets/gray2.png");
  background-size: 100% 100%;
  font-weight: 800;
  font-size: 36px;
  color: #FFFFFF;
  line-height: 84px;
  text-align: center;
  margin-top: 0px;
  position: relative;
  z-index: 3;
}

.part1 {
  padding-left: 15px;
  padding-right: 15px;
  height: 90px;
  width: fit-content;
  background-image: url("@/assets/green.png");
  background-size: 100% 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-weight: 600;
  font-size: 40px;
  color: #FFFFFF;
  text-align: center;
  line-height: 90px;
}

.part2 {
  height: 120px;
  background-image: url("@/assets/gray1.png");
  background-size: 100% 240px;
  display: flex;
  align-items: center;
  width: fit-content;
  position: relative;
  z-index: 3;
}
.p5{
  background-image: url("@/assets/green1.png");
  background-size: 100% 100%;
  width: 120px;
  height: 100px;
  line-height: 100px;
  font-size: 72px;
  color: black;
  font-weight: 600;
  text-align: center;
  position: absolute;
  right: -120px;
}

.p1 span {
  opacity: 0;
}

.part2 .p1 {
  font-weight: 500;
  font-size: 48px;
  line-height: 120px;
  color: #FFFFFF;
  margin-right: 0px;
  white-space: nowrap;
  text-align: left;
  margin-left: 20px;
  position: relative;
}

.part2 .p2 {
  font-family: PingFang SC;
  font-weight: 800;
  font-size: 72px;
  height: calc(100% - 3px);
  margin-top: 3px;
  color: #FFFFFF;
  background-color: black;
  border-left: 2px solid rgba(255,255,255,0.3);
  width: 103px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.part2 .p3 {
  width: 120px;
  height: calc(100% - 3px);
  margin-top: 3px;
  background-image: url("@/assets/blue.png");
  background-size: 100% 100%;
  font-weight: 800;
  font-size: 72px;
  color: #0E1012;
  line-height: 100px;
  text-align: center;
  margin-right: 15px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.part2 .p4 {
  width: 100px;
  height: 100px;
  background: #F7F9FF;
  border-radius: 16px;
  font-weight: 800;
  font-size: 60px;
  color: #0E1012;
  line-height: 100px;
  text-align: center;
  margin-left: 10px;
}

.part3 {
  height: 120px;
  background-image: url("@/assets/gray1.png");
  background-size: 100% 240px;
  background-position: 0px 361px;
  display: flex;
  align-items: center;
  width: fit-content;
  position: relative;
  z-index: 3;
}

.part3 .p1 {
  font-weight: 500;
  font-size: 48px;
  line-height: 120px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  text-align: left;
  margin-left: 20px;

}

.part3 .p1 div:nth-child(1) {
  margin-right: 0px;
}

.part2 .p1 img {
  width: 42px;
  display: block;
  right: 30px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin: auto;
}

.part3 .p2 {
  font-family: PingFang SC;
  font-weight: 800;
  font-size: 72px;
  color: #FFFFFF;
  background-color: black;
  border-left: 2px solid rgba(255,255,255,0.3);
  width: 103px;
  text-align: center;
  height: calc(100% - 3px);
  margin-top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.part3 .p3 {
  width: 120px;
  height: calc(100% - 3px);
  margin-top: 3px;
  background-image: url("@/assets/blue.png");
  background-size: 100% 100%;
  font-weight: 800;
  font-size: 72px;
  color: #0E1012;
  line-height: 100px;
  text-align: center;
  margin-right: 15px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.part3 .p4 {
  width: 100px;
  height: 100px;
  background: #F7F9FF;
  border-radius: 16px;
  font-weight: 800;
  font-size: 60px;
  color: #0E1012;
  line-height: 100px;
  text-align: center;
  margin-left: 10px;
}
</style>
